// import { IconButton } from "@material-ui/core";
import React, { useState, useEffect } from "react";
// import icon1 from "../../src/static/pass-view-white-asc.png";
import TerminosAdvices from "../TerminosAdvices/TerminosAdvices";
import "./RecolectDataStart.scss";
import { infoMessage } from "../utils/ErrorHandler/errorHandler";
import { useHistory } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import StorageManager from "../utils/StorageManager";

import { Button2 } from "../utils/Buttons/Buttons";

function RecolectDataStart(props) {
  let history = useHistory();

  const [country, setCountry] = useState("COL");
  const [data2, setData] = useState();
  const [selectdata, setSelectData] = useState("1");
  // const needsRut = props.dataRepo.needsRut;
  const needsRut = props.dataRepo.needsRut;
  const docTypes = props.dataRepo.supportedDocTypes;

  const [needsRedirect, setNeedsRedirect] = useState(false);

  const [docType, setDocType] = useState("CEDULA");

  const [index, setIndex] = useState(0);

  const [storageManager, _] = useState(new StorageManager());

  const [needsPhone, setNeedsPhone] = useState(true);
  const [needsName, setNeedsName] = useState(false);
  const [needsLastName, setNeedsLastName] = useState(true);

  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [lastName, setLastName] = useState("");
  const [userDoc, setUserDoc] = useState("");
  const [identificationNumber, setIdentificationNumber] = useState("");
  const [name, setName] = useState("");

  const query = useQuery();
  const UrlCode = query.get("code");

  const [needsPersona, setNeedsPersona] = useState(false);

  function getHintIdentification() {
    if (docType === "PASAPORTE") {
      return "Ej: BC1234567";
    }
    switch (country) {
      case "COL":
        if (docType === "CEDULA") {
          return "Sin puntos Ej: 80457568";
        }
        break;
      case "CR":
        if (docType === "CEDULA") {
          return "Sin espacios Ej: 103690574";
        }
        break;
      case "NI":
        if (docType === "CEDULA") {
          return "Sin guiones Ej: 0810930001M";
        }
        break;
      case "PE":
        if (docType === "CEDULA") {
          return "Sin guión Ej: 434518267";
        }
        break;

      default:
        return "Sin puntos Ej: 804575685";
    }
    return "Sin puntos Ej: 804575685";
  }

  function getHintPhoneNumber() {
    switch (country) {
      case "COL":
        if (docType === "CEDULA") {
          return "Sin indicativo Ej: 3205456652";
        }
        break;
      case "CR":
        if (docType === "CEDULA") {
          return "Sin indicativo Ej: 86834456";
        }
        break;
      case "NI":
        if (docType === "CEDULA") {
          return "Sin indicativo Ej: 9376564";
        }
        break;
      case "PE":
        if (docType === "CEDULA") {
          return "Sin indicativo Ej: 9376564";
        }
        break;

      default:
        return "Sin indicativo Ej: 3205456652";
    }
    return "Sin indicativo Ej: 3205456652";
  }

  function handleIdentificationChange(e) {
    let document = e.target.value;
    let onlyDigitsReg = /^\d+$/;
    let onlyDigitsRequired = false;
    let numDigits = 10;
    let capitalize = false;

    if (document.length === 0) {
      setIdentificationNumber(document);
    }

    if (docType === "PASAPORTE") {
      onlyDigitsRequired = false;
      numDigits = 9;
      capitalize = true;
    }

    switch (country) {
      case "COL":
        if (docType === "CEDULA") {
          onlyDigitsRequired = true;
          numDigits = 10;
        }
        break;
      case "CR":
        if (docType === "CEDULA") {
          onlyDigitsRequired = true;
          numDigits = 9;
        }
        break;
      case "NI":
        if (docType === "CEDULA") {
          onlyDigitsRequired = false;
          numDigits = 14;
          capitalize = true;
        }
        break;
      case "PE":
        if (docType === "CEDULA") {
          onlyDigitsRequired = true;
          numDigits = 9;
        }
        break;
      default:
        onlyDigitsRequired = false;
        numDigits = 10;
    }

    if (onlyDigitsRequired) {
      if (onlyDigitsReg.test(document)) {
        setIdentificationNumber(document.slice(0, numDigits));
      }
    } else {
      if (capitalize) {
        document = document.toUpperCase();
      }
      setIdentificationNumber(document.slice(0, numDigits));
    }
  }

  function getIdentificationInputMode() {
    if (docType === "PASAPORTE") {
      return "text";
    }
    switch (country) {
      case "COL":
        if (docType === "CEDULA") {
          return "numeric";
        }
        break;
      case "CR":
        if (docType === "CEDULA") {
          return "numeric";
        }
        break;
      case "NI":
        if (docType === "CEDULA") {
          return "text";
        }
        break;
      case "PE":
        if (docType === "CEDULA") {
          return "numeric";
        }
        break;
      default:
        return "text";
    }
    return "text";
  }

  function getIdentificationPattern() {
    if (docType === "PASAPORTE") {
      return "[a-zA-Z0-9]*";
    }
    switch (country) {
      case "COL":
        if (docType === "CEDULA") {
          return "[0-9]*";
        }
        break;
      case "CR":
        if (docType === "CEDULA") {
          return "[0-9]*";
        }
        break;
      case "NI":
        if (docType === "CEDULA") {
          return "[a-zA-Z0-9]*";
        }
        break;
      case "PE":
        if (docType === "CEDULA") {
          return "[0-9]*";
        } else if (docType === "PASAPORTE") {
          return "[a-zA-Z0-9]*";
        }
        break;
      default:
        return undefined;
    }

    return undefined;
  }
  // console.log('UrlCode',UrlCode)
  function blockDataEntry(data) {
    props.setCanEdit(false);
    if (!data.lastName) {
      // document.getElementById("nombreUser").value = " ";
      // setLastName(" ");
    } else {
      if (document.getElementById("userLastName"))
        document.getElementById("userLastName").disabled = true;
    }

    if (!data.identification) {
      document.getElementById("cedulaUser").value = " ";
    } else {
      document.getElementById("cedulaUser").disabled = true;
    }

    if (!data.email) {
      document.getElementById("correoUser").value = " ";
    } else {
      document.getElementById("correoUser").disabled = true;
    }

    if (needsPhone) {
      if (!data.cellphone) {
        setPhone(" ");
      } else {
        document.getElementById("celularUser").disabled = true;
      }
    }

    if (data.naturalPerson === true || data.naturalPerson === false) {
      document.getElementById("personSelector").disabled = true;
    }

    if (needsRut && data.naturalPerson) {
      document.getElementById("rutUser").disabled = true;
    } else if (!data.naturalPerson) {
      document.getElementById("nitUser").disabled = true;
    }
  }
  //set agFinal as not yet activated
  useEffect(() => {
    storageManager.setGenericString("false", "agFinal");
  }, []);

  useEffect(() => {
    //Skip if not needed
    let project = storageManager.getProject();
    console.log(project);
    if (project.extraConfig) {
      if (project.extraConfig.askInitialinfo === false) {
        history.push(`/advices?code=${UrlCode}`);
        return;
      }
    }
    if (project.country) {
      setCountry(project.country);
    }
    if (!data2) {
      // preload data

      props.api.getPreloadedData().then((data) => {
        setData(data);
        console.log("PreloadedData->>>>>>");
        console.log(data, "CODEE");

        if (!data.naturalPerson) {
          document.getElementById("personSelector")[1].selected = true;
          document.getElementById("personSelector")[0].selected = false;
          setSelectData("2");
        } else {
          document.getElementById("personSelector")[0].selected = true;
          document.getElementById("personSelector")[1].selected = false;
          setSelectData("1");
        }

        if (data.lastName && needsLastName) {
          setNeedsRedirect(true);
          // document.getElementById("userLastName").value = data.lastName;+
          document.getElementById("userLastName").disabled = true;
          setLastName(data.lastName);
          // blockDataEntry(data);
        }
        if (data.identification) {
          setNeedsRedirect(true);
          document.getElementById("cedulaUser").value = data.identification;
          document.getElementById("cedulaUser").disabled = true;
          setIdentificationNumber(data.identification);
          // blockDataEntry(data);
        }
        if (data.email) {
          setNeedsRedirect(true);
          document.getElementById("correoUser").value = data.email;
          document.getElementById("correoUser").disabled = true;
          // blockDataEntry(data);
        }
        if (data.cellphone && needsPhone) {
          setNeedsRedirect(true);
          // document.getElementById("celularUser").value = data.cellphone;
          document.getElementById("celularUser").disabled = true;
          setPhone(data.cellphone);
          // blockDataEntry(data);
        }
        if (needsRut && data.rut && data.naturalPerson) {
          setNeedsRedirect(true);
          document.getElementById("rutUser").value = data.rut;
          document.getElementById("rutUser").disabled = true;

          // blockDataEntry(data);
        }
        if (data.naturalPerson === false && data.nit) {
          setNeedsRedirect(true);
          document.getElementById("nitUser").value = data.nit;
          document.getElementById("nitUser").disabled = true;
          // blockDataEntry(data);
        }
        if (data.identificationType && data.identificationType !== "CEDULA") {
          storageManager.setDocType(data.identificationType.toUpperCase());
          setDocType(data.identificationType.toUpperCase());
        }
      });
    }
  }, []);

  //needsPhone
  useEffect(() => {
    needsPhoneF();
  }, []);
  async function needsPhoneF() {
    let project = await props.api.getProject();
    if (project.hasOtp === false) {
      console.log("setNeedsPhone: False!!");
      setNeedsPhone(false);
    }
    if (project.needsAskName === true) {
      setNeedsName(true);
    }
    if (project.needsAskLastName === false) {
      setNeedsLastName(false);
    }
    if (project.usesLegalperson === true) {
      setNeedsPersona(true);
    }
  }

  const checkPhoneEmail = async () => {
    let email2 = document.getElementById("correoUser").value;
    let phone2 = phone;
    let res = await props.api.checkPhoneEmail(email2, phone2);
    let resCheckMaxTries = await props.api.checkMaxTries(identificationNumber);
    console.log("CheckMaxTries");
    console.log(resCheckMaxTries);
    if (resCheckMaxTries && resCheckMaxTries.accepted === false) {
      infoMessage(resCheckMaxTries.message).then(() => {
        window.location.href = resCheckMaxTries.redirectionUrl;
      });

      return;
    }
    if (res) {
      if (res.canContinue) {
        final();
      } else {
        infoMessage(
          "El correo o el celular usado ya se encuentra registrado! Por favor revisa los datos e intenta nuevamente"
        );
      }
    } else {
      infoMessage(
        "El correo o el celular usado ya se encuentra registrado! Por favor revisa los datos e intenta nuevamente"
      );
    }
  };

  const final = async () => {
    //load values

    var apellido = lastName;
    var cedula = identificationNumber;
    var celular = phone;
    var correo = document.getElementById("correoUser").value;
    var nombre = name;
    var nit = "";
    var rut = "";
    if (selectdata === "2") {
      nit = document.getElementById("nitUser").value;
    }
    if (selectdata === "1" && needsRut) {
      rut = document.getElementById("rutUser").value;
    }
    console.log(apellido);
    console.log(cedula);
    console.log(correo);

    var re = /\S+@\S+\.\S+/;
    //check values
    if (correo === "" || cedula === "") {
      infoMessage("Por favor completa todos los datos");
      return;
    }
    if (selectdata === "2" && nit === "") {
      infoMessage("Por favor completa todos los datos");
      return;
    }
    if (selectdata === "1" && needsRut && rut === "") {
      infoMessage("Por favor completa todos los datos");
      return;
    }

    if (!re.test(correo)) {
      infoMessage("Formato de correo inválido.");
      return;
    }
    if (needsPhone) {
      if (celular.length !== 10 && celular.length !== 8) {
        infoMessage("Formato de celular inválido.");
        return;
      }
    }

    if (needsLastName) {
      if (apellido === "") {
        infoMessage("Ingresa tu apellido");
        return;
      }
    }

    if (selectdata === "2" && nit.length !== 9 && nit.length !== 10) {
      infoMessage("NIT invalido.");
      return;
    }

    //verify nombre
    if (needsName) {
      if (nombre === "") {
        infoMessage("Ingresa tu nombre");
        return;
      }
    }
    //send data
    await props.api.addUserInitialInformation(
      cedula,
      apellido,
      correo,
      celular,
      docType,
      nombre
    );

    props.setPhoneEmail(celular, correo);

    if (selectdata === "2") {
      await props.api.sendInitUser(nit);
    }
    if (selectdata === "1" && needsRut) {
      await props.api.setRut(rut);
    }
    // props.nextPage();
    // next();
    history.push(`/advices?code=${UrlCode}`);
    //  history.push(`/final?code=${UrlCode}`);
  };

  const redirectWrongData = async () => {
    try {
      var url = await props.api.getWrongDataUrl();
      window.location = url;
    } catch (e) {
      console.log(e);
    }
  };
  //show the textsinputs

  if (!data2) {
    return (
      <div className="container">
        <div className="row"></div>
      </div>
    );
  }

  const changeDocType = (pDocType) => {
    storageManager.setDocType(pDocType);
    setDocType(pDocType);
  };

  return (
    <div className="container recolectStart">
      {/* {index === 0 && ( */}

      <div className="row">
        <div className="col-12">
          <p className=" txt_enter">
            Es momento de<br></br> conocerte<br></br> un poco más
          </p>
        </div>
        {/* ----------subText--------------- */}
        <div className="col-12">
          <h5 className="txt_enter2">Ingresa los siguientes datos:</h5>
        </div>
        {/* ----------INPUT APELLIDO--------------- */}
        {needsLastName && (
          <div className="col-12">
            <h6 className="color-white">APELLIDO</h6>
            <input
              className="input-form"
              id="userLastName"
              maxLength="15"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            ></input>
          </div>
        )}

        {/* ----------INPUT NOMBRE--------------- */}

        {needsName && (
          <div className="col-12">
            <h6 className="color-white">NOMBRE</h6>
            <input
              className="input-form"
              id="nombreUser2"
              maxLength="20"
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></input>
          </div>
        )}
        {/* ----------INPUT_2--------------- */}

        {/* Conditional Selector */}
        {docTypes.length > 0 && (
          <div className="col-12" style={{ marginTop: "2vh" }}>
            <h6 className="color-white">TIPO DE DOCUMENTO</h6>
            <select
              id="docTypeSelect"
              className="select-input"
              onChange={(e) => {
                changeDocType(e.target.value);
                setIdentificationNumber("");
              }}
            >
              {docTypes.map((val, i) => {
                return (
                  <option key={i} className="option-select" value={val}>
                    {val}
                  </option>
                );
              })}
            </select>
          </div>
        )}

        <div className="col-12">
          <h6 className="color-white">No. de DOCUMENTO</h6>

          <input
            id="cedulaUser"
            pattern={getIdentificationPattern()}
            inputMode={getIdentificationInputMode()}
            type="text"
            onChange={handleIdentificationChange}
            value={identificationNumber}
            className="input-form"
          />
          <p
            style={{
              color: "rgba(235, 255, 0, 0.7)",
              textAlign: "center",
              fontSize: "10px",
              fontWeight: "500",
              marginTop: "-10px",
            }}
          >
            {getHintIdentification()}
          </p>
        </div>
        {/* ----------Phone Input--------------- */}
        {needsPhone && (
          <div className="col-12">
            <h6 className="color-white">CELULAR </h6>
            <input
              id="celularUser"
              type="text"
              pattern="[0-9]*"
              inputMode="numeric"
              // onInput={(e) => (e.target.value = e.target.value.slice(0, 10))}
              onChange={(e) => {
                let onlyDigitsReg = /^\d+$/;
                if (
                  onlyDigitsReg.test(e.target.value) ||
                  e.target.value.length === 0
                ) {
                  setPhone(e.target.value);
                }
              }}
              className="input-form"
              value={phone}
            ></input>
            <p
              style={{
                color: "rgba(235, 255, 0, 0.7)",
                textAlign: "center",
                fontSize: "10px",
                fontWeight: "500",
                marginTop: "-10px",
              }}
            >
              {getHintPhoneNumber()}
            </p>
          </div>
        )}

        {/* ----------INPUT_4--------------- */}
        <div className="col-12">
          <h6 className="color-white">CORREO</h6>

          <input
            id="correoUser"
            type="email"
            maxLength="40"
            className="input-form"
          ></input>
        </div>
        {/* ----------INPUT_5_WITH_SELECT--------------- */}
        <div className="col-12" hidden={!needsPersona}>
          <h6 className="color-white">PERSONA</h6>
          <select
            id="personSelector"
            className="select-input"
            onChange={(e) => {
              setSelectData(e.target.value);
            }}
          >
            <option className="option-select" value="1">
              Natural
            </option>
            <option className="option-select" value="2">
              Jurídica
            </option>
          </select>
        </div>

        {/* ----------CONDITIONAL_OF_SELECT_OPTIONS--------------- */}
        <div className="col-12">
          {selectdata === "1" && needsRut && (
            <>
              <h6 className="color-white">RUT</h6>
              <input
                id="rutUser"
                type="number"
                maxLength="40"
                className="input-form"
              ></input>{" "}
            </>
          )}
          {selectdata === "2" && (
            <>
              <h6 className="color-white">NIT</h6>
              <input
                id="nitUser"
                type="number"
                maxLength="40"
                className="input-form"
              ></input>
            </>
          )}
        </div>
        {/* ----------BUTTON--------------- */}
        <div className="col-12" style={{ margin: "20px 0 0 0 " }}>
          <Button2
            text="SIGUIENTE"
            width={"200px"}
            style={{ marginTop: "50px" }}
            className="btn-default btn_next"
            onClick={() => checkPhoneEmail()}
          ></Button2>
        </div>
        {needsRedirect && (
          <div className="col-12">
            <button
              type="button"
              className="btn btn-default"
              onClick={() => redirectWrongData()}
              style={{
                borderRadius: "20px",
                backgroundColor: "transparent",
                border: "none",
                color: "WHITE",
                marginTop: "30px",
              }}
            >
              <h6 className="color-white">CORREGIR DATOS</h6>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
export default RecolectDataStart;
