import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import React from "react";
import "./errorHandler.scss";

import alertIcon from "./../../../assets/alert.png";
import err_reflejo from "./../../../assets/err_reflejo_1.png";
import err_borroso from "./../../../assets/err_borroso_1.png";
import hint_correct from "./../../../assets/hint_correct.png";
import hint_correct_correct from "./../../../assets/hint_correct_borroso.png";
import icon_wrong from "./../../../assets/hint_wrong_icon.png";
import icon_correct from "./../../../assets/hint_correct_icon.png";
import tst_cc from "./../../../assets/test_cc.png";

const MySwal = withReactContent(Swal);

function hintMessageReflejo(image, bg1, bg2) {
  return MySwal.fire({
    customClass: {
      container: "style_0 swal-hint-container",
      popup: "style_1 popup_hint",
      content: "style_1 content_hint",
      htmlContainer: "html_hint",
      confirmButton: "style_3",
    },
    showConfirmButton: false,
    html: (
      <div
        className="App innerHtml"
        style={{
          background: `radial-gradient(${bg1}, ${bg2})`,
          height: "100%",
        }}
      >
        <div className="col main_col" style={{ color: "white" }}>
          {/* Header */}
          <div className="row first_row">
            <div className="col txt-col">
              <div className="first_text">HAY</div>
              <div className="first_text">UN REFLEJO</div>
            </div>
            <div>
              <img
                src={alertIcon}
                style={{ width: "30px", height: "30px" }}
              ></img>
            </div>
          </div>
          {/* First images */}
          <div className="row second_row">
            <div className="col-6 col_img">
              <img className="img_small" src={err_reflejo}></img>
              <img className="img_icon" src={icon_wrong}></img>
            </div>
            <div className="col-6 col_img">
              <img className="img_small" src={hint_correct}></img>
              <img className="img_icon" src={icon_correct}></img>
            </div>
          </div>
          {/* Info */}
          <div className="row third_row">
            <div className="col">
              <div className="second-text">EN TU DOCUMENTO</div>
              <div className="third-text">HAY REFLEJOS</div>
            </div>
          </div>
          {/* Big Image */}
          <div className="row user-image-row">
            <div className="user-image-div">
              <CustomImg className="col" data={image} />
            </div>
          </div>
          {/* Capture again */}
          <div className="row fourth-row">
            <div className="col fourth-text">VOLVER A CAPTURAR</div>
          </div>
          <div className="row row-button">
            <div className="col">
              <button className="button-retry" onClick={() => MySwal.close()}>
                CLICK AQUÍ
              </button>
            </div>
          </div>
        </div>
      </div>
    ),
  });
}

function hintMessageBorroso(image, bg1, bg2) {
  return MySwal.fire({
    customClass: {
      container: "style_0 swal-hint-container",
      popup: "style_1 popup_hint",
      content: "style_1 content_hint",
      htmlContainer: "html_hint",
      confirmButton: "style_3",
    },
    showConfirmButton: false,
    html: (
      <div
        className="App innerHtml"
        style={{
          background: `radial-gradient(${bg1}, ${bg2})`,
          height: "100%",
        }}
      >
        <div className="col main_col" style={{ color: "white" }}>
          {/* Header */}
          <div className="row first_row">
            <div className="col txt-col">
              <div className="first_text">NO VEO</div>
              <div className="first_text">TU DOCUMENTO</div>
            </div>
            <div>
              <img
                src={alertIcon}
                style={{ width: "30px", height: "30px" }}
              ></img>
            </div>
          </div>
          {/* First images */}
          <div className="row second_row">
            <div className="col-6 col_img">
              <img className="img_small" src={err_borroso}></img>
              <img className="img_icon" src={icon_wrong}></img>
            </div>
            <div className="col-6 col_img">
              <img className="img_small" src={hint_correct_correct}></img>
              <img className="img_icon" src={icon_correct}></img>
            </div>
          </div>
          {/* Info */}
          <div className="row third_row">
            <div className="col">
              <div className="second-text">TU DOCUMENTO</div>
              <div className="third-text">ESTÁ BORROSO</div>
            </div>
          </div>
          {/* Big Image */}
          <div className="row user-image-row">
            <div className="user-image-div">
              <CustomImg className="col" data={image} />
            </div>
          </div>
          {/* Capture again */}
          <div className="row fourth-row">
            <div className="col fourth-text">VOLVER A CAPTURAR</div>
          </div>
          <div className="row row-button">
            <div className="col">
              <button className="button-retry" onClick={() => MySwal.close()}>
                CLICK AQUÍ
              </button>
            </div>
          </div>
        </div>
      </div>
    ),
  });
}

function hintMessageRecorteAgente(image, bg1, bg2, message){
  return MySwal.fire({
    customClass: {
      container: "style_0 swal-hint-container",
      popup: "style_1 popup_hint",
      content: "style_1 content_hint",
      htmlContainer: "html_hint",
      confirmButton: "style_3",
    },
    showConfirmButton: false,
    html: (
      <div className="App innerHtml"
      style={{
        background: `radial-gradient(${bg1}, ${bg2})`,
        height: "100%",
      }}>
        <div className="contenedorSeleccionado" style={{ color: "white" }}>
          <div className="messageError">
            <div className="messageErrorText">{message}</div>
          </div>
          <div className="contenedorImagen">
            <img src={image} alt="Imagen Error"></img>
          </div>
          <div className="row fourth-row">
            <div className="col fourth-text">VOLVER A CAPTURAR</div>
          </div>
          <div className="row row-button">
            <div className="col">
              <button className="button-retry" onClick={() => MySwal.close()}>
                CLICK AQUÍ
              </button>
            </div>
          </div>
        </div>
      </div>
    ),
  });
}

const CustomImg = ({ data }) => (
  <img className="user-image" src={`data:image/png;base64,${data}`} />
);
// const CustomImg = ({ data }) => <img className="user-image" src={tst_cc} />;

export { hintMessageReflejo, hintMessageBorroso, hintMessageRecorteAgente };
