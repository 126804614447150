import React, { useState } from "react";
import icono2 from "./../../assets/scan.png";
import "./IntroCheck.scss";
import icon3 from "./../../assets/LOCATION.png";
import icon4 from "./../../assets/Group.png";
import Checkbox from "@material-ui/core/Checkbox";
import { Row, Col } from "react-bootstrap";
import { Button1, Button2 } from "./../utils/Buttons/Buttons";
import { useHistory, Link } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import { infoMessage } from "../utils/ErrorHandler/errorHandler";
function Introcheck(props) {
  let history = useHistory();
  const query = useQuery();
  const UrlCode = query.get("code");
  const [acept, setAcept] = useState(false);
  const onChange = (e) => {
    setAcept(!acept);
  };

  function showAlert() {
    if (acept) {
      console.log("PUSHING ROUTE!!!!! " + `/intro/?code=${UrlCode}`);
      history.push(`/intro/?code=${UrlCode}`);
    } else {
      infoMessage("DEBES ACEPTAR LOS TERMINOS Y CONDICIONES PARA CONTINUAR");
    }
  }

  return (
    <div className="introcheck">
      <img src={icono2} className="img-icon2" alt="img" />
      <p style={{ marginTop: "5px" }} className="txt-line-one">
        Reconocimiento facial <br></br> y datos biométricos
      </p>
      <div className="container-terms">
        <Row>
          <Col xs={2}>
            <img src={icon3} />
          </Col>
          <Col>
            <div>
              {" "}
              <p className="txt-lines-title">Ubicación</p>
            </div>

            <p className="txt-lines">
              · Acceder a tu ubicación aproximada (basada en la red) <br></br>·
              Acceder a tu ubicación precisa (basada en red GPS)
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={2}>
            {" "}
            <img src={icon4} />
          </Col>
          <Col>
            <div>
              {" "}
              <p className="txt-lines-title">Otros</p>
            </div>

            <p className="txt-lines">
              · Ver conexiones de red <br />· Recibir datos de Internet
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={2}>
            <Checkbox
              size="medium"
              className="checkbox_accepterms"
              style={{
                transform: "scale(1.5)",
              }}
              checked={acept}
              onChange={onChange}
              inputProps={{
                "aria-label": "checkbox with default color",
              }}
            />
          </Col>
          <Col>
            <p className="txt-lines" style={{ margin: "0 0 0 5px" }}>
              Doy mi consentimiento libre, expreso que he sido informado de los
              términos y condiciones, política de privacidad y tratamiento de
              datos sensibles.
            </p>
            <Link to={`/readmore/?code=${UrlCode}`}>
              <p className="txt-read-more">(leer más)</p>
            </Link>
          </Col>
        </Row>

        <Button1
          text="RECHAZAR"
          onClick={() => {
            infoMessage(
              "DEBES ACEPTAR LOS TERMINOS Y CONDICIONES PARA CONTINUAR"
            );
          }}
          width="47vw"
          height="5vh"
        />
        <div style={{ marginTop: "0.5em" }}>
          <Button2
            text="ACEPTAR"
            onClick={() => showAlert()}
            width="47vw"
            height="5vh"
          />
        </div>
      </div>
    </div>
  );
}
export default Introcheck;
