import React, { useState, useEffect } from "react";
import icono1 from "../../static/ICO USER.png";
import icono2 from "./../../assets/scan.png";
import icono3 from "./../../assets/video.png";
import icono4 from "../../static/ICO UBICACION.png";
import icono5 from "./../../assets/microphone.png";
import icono6 from "../../static/ICO SMS.png";
// import iconoanteia from "../static/00 PAV 01.png";
import iconoanteia from "../../assets/logo_anteia.png";
import Checkbox from "@material-ui/core/Checkbox";
import { Container, Row, Col } from "react-bootstrap";
import IntroLogo from "../IntroLogo/IntroLogo";
import IntroPage from "./../IntroPage/IntroPage";
// import { infoMessage } from "./utils/errorHandler";
import { Link, useHistory } from "react-router-dom";
import "./TerminosIntroPage.scss";
import useQuery from "../../hooks/useQuery";
import { isMobile } from "../utils/utils";
import { infoMessage } from "../utils/ErrorHandler/errorHandler";
import alert from "./../../assets/alert.png";
import { Button2 } from "./../utils/Buttons/Buttons";

function TerminosIntroPage(props) {
  let history = useHistory();
  const [index, setIndex] = useState(0);
  const [acept, setAcept] = useState(false);
  const [showadd, setShowadd] = useState(null);
  const query = useQuery();
  // console.log( new URLSearchParams(useLocation().search).get("code"), 'URL')

  const UrlCode = query.get("code");
  // console.log('UrlCode',UrlCode)
  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((index) => (index < 2 ? index + 1 : index));
    }, 4000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  // function showAlert() {
  //   //TODO: Mostrar alerta cuando no está chequeado el chulito (definir mensaje correctamente, no poner "chulito pls")
  // }
  useEffect(() => {
    if (!isMobile()) {
      history.push(`/qrFlow?code=${UrlCode}`);
    }
  }, []);

  const onChange = (e) => {
    setAcept(!acept);
    setShowadd(!showadd);
  };

  function getLogo() {
    if (props.logoUrl && props.logoUrl !== "") {
      return props.logoUrl;
    } else {
      return iconoanteia;
    }
  }

  console.log(props.logoUrl);

  return (
    <div className="App container terms-conditions">
      <div>
        {/* ------------------INTRO__LOGO------------------------ */}
        {index === 0 && (
          <div>
            <IntroLogo logoUrl={props.logoUrl} />
          </div>
        )}
        {/* ------------------INTRO__TEXTO------------------------ */}
        {index === 1 && <IntroPage />}
        {/* ------------------PRIMER__SCREEN------------------------ */}
        {index === 2 && (
          <div className="col-12">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontWeight: "700",
                    fontSize: "12px",
                    color: "#fff",
                    margin: "0",
                  }}
                >
                  Antes de comenzar
                </p>
                {props.logoUrl !== "a" && (
                  <>
                    {" "}
                    {props.logoUrl && props.logoUrl !== "error" ? (
                      <img
                        src={props.logoUrl}
                        loading="eager"
                        alt="logo empresa"
                        style={{
                          maxWidth: "300px",
                          maxHeight: "120px",
                          margin: "20px 0 24px 0",
                          width: "auto",
                          height: "auto",
                        }}
                      />
                    ) : (
                      <img
                        src={iconoanteia}
                        loading="eager"
                        alt="logo anteia"
                        style={{
                          maxWidth: "300px",
                          margin: "20px 0 24px 0",
                          maxHeight: "60px",
                          width: "auto",
                          height: "auto",
                        }}
                      />
                    )}
                  </>
                )}
                <p
                  style={{
                    width: "168px",
                    textAlign: "center",
                    fontWeight: "700",
                    fontSize: "12px",
                    color: "#fff",
                  }}
                >
                  necesita acceder, recolectar y almacenar datos de:
                </p>
              </div>

              {/* -----------------------CONTAINER_1------------------------------- */}

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {/*----------identidad------------*/}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "40px",
                  }}
                >
                  <div
                    className="instruction_icon"
                    style={{ marginRight: "17px" }}
                  >
                    <img
                      src={icono1}
                      alt="Icono"
                      style={{
                        width: "30px",
                        height: "31.67px",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                      justifyContent: "start",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: "700",
                        fontSize: "12px",
                        lineHeight: "11px",
                        color: "#fff",
                        margin: "0 0 2px 0",
                        fontFamily: "Open Sans",
                      }}
                    >
                      Identidad
                    </p>
                    <p
                      style={{
                        fontWeight: "400",
                        fontSize: "11px",
                        lineHeight: "11px",
                        color: "#fff",
                        width: "160px",
                        margin: "0",
                        textAlign: "start",
                        fontFamily: "Open Sans",
                      }}
                    >
                      Consultar la identidad y estado del dispositivo
                    </p>
                  </div>
                </div>

                {/*----------video------------*/}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "40px",
                  }}
                >
                  <div
                    className="instruction_icon"
                    style={{ marginRight: "17px" }}
                  >
                    <img
                      src={icono3}
                      alt="Icono"
                      style={{ width: "32.5px", height: "32.5px" }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                      justifyContent: "start",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: "700",
                        fontSize: "12px",
                        lineHeight: "11px",
                        color: "#fff",
                        margin: "0 0 2px 0",
                        fontFamily: "Open Sans",
                      }}
                    >
                      Cámara de vídeo
                    </p>
                    <p
                      style={{
                        fontWeight: "400",
                        fontSize: "11px",
                        lineHeight: "11px",
                        color: "#fff",
                        width: "160px",
                        margin: "0",
                        textAlign: "start",
                        fontFamily: "Open Sans",
                      }}
                    >
                      {" "}
                      Realizar fotografías y videos
                    </p>
                  </div>
                </div>

                {/*----------microfono------------*/}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "40px",
                  }}
                >
                  <div
                    className="instruction_icon"
                    style={{ marginRight: "17px" }}
                  >
                    <img
                      src={icono5}
                      alt="Icono"
                      style={{ width: "33px", height: "33px" }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                      justifyContent: "start",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: "700",
                        fontSize: "12px",
                        lineHeight: "11px",
                        color: "#fff",
                        margin: "0 0 2px 0",
                        fontFamily: "Open Sans",
                      }}
                    >
                      Micrófono
                    </p>
                    <p
                      style={{
                        fontWeight: "400",
                        fontSize: "11px",
                        lineHeight: "11px",
                        color: "#fff",
                        width: "160px",
                        margin: "0",
                        textAlign: "start",
                        fontFamily: "Open Sans",
                      }}
                    >
                      Grabar sonido
                    </p>
                  </div>
                </div>
                {/*----------mensajes------------*/}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "40px",
                  }}
                >
                  <div
                    className="instruction_icon"
                    style={{ marginRight: "17px" }}
                  >
                    <img
                      src={icono6}
                      alt="Icono"
                      style={{ width: "33.33px", height: "30.97px" }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                      justifyContent: "start",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: "700",
                        fontSize: "12px",
                        lineHeight: "11px",
                        color: "#fff",
                        margin: "0 0 2px 0",
                        fontFamily: "Open Sans",
                      }}
                    >
                      SMS
                    </p>
                    <p
                      style={{
                        fontWeight: "400",
                        fontSize: "11px",
                        lineHeight: "11px",
                        color: "#fff",
                        width: "160px",
                        margin: "0",
                        textAlign: "start",
                        fontFamily: "Open Sans",
                      }}
                    >
                      · Recibir mensajes de texto (sms)<br></br>· Enviar
                      mensajes (sms) <br></br>· Leer mensajes (sms)
                    </p>
                  </div>
                </div>
              </div>

              {/*  -----------------------CONTAINER_2------------------------------- */}

              {/*       <Container className="container_accepterms">
                <Row>
                  <Col xs={2}>
                    <Checkbox
                      size="medium"
                      className="checkbox_accepterms"
                      style={{
                        transform: "scale(1.5)",
                      }}
                      checked={acept}
                      onChange={onChange}
                      inputProps={{
                        "aria-label": "checkbox with default color",
                      }}
                    />
                    {console.log(acept, " state")}
                  </Col>
                  <Col className="col_accepterms">
                    <p className="text-terms" style={{ textAlign: "left" }}>
                      DOY MI CONSENTIMIENTO LIBRE, EXPRESO QUE HE SIDO INFORMADO
                      DE LOS TÉRMINOS Y CONDICIONES, POLÍTICA DE PRIVACIDAD Y
                      TRATAMIENTO DE DATOS SENSIBLES .
                      <a href="https://storage.googleapis.com/anteia-cdn/tyc/politica%20privacidad%20oct%202021.pdf">
                        (LEER MÁS)
                      </a>
                    </p>
                  </Col>
                </Row>
                {showadd === false && (
                  <div className="row-check">
                    <div className="check-msg">
                      <p>
                        DEBES DAR CHECK PARA CONTINUAR Y ACEPTAR LOS TÉRMINOS Y
                        CONDICIONES, PÓLITICA DE PRIVACIDAD Y TRATAMIENTO DE
                        DATOS SENSIBLES
                      </p>
                    </div>

                    <div className="check-msg-img">
                      <img src={alert} alt="alert" />
                    </div>
                  </div>
                )}
              </Container> */}

              {/*  -----------------------CONTAINER_3_BUTTONS------------------------------- */}

              <Col className="conatiner-buttons">
                {/* <button type="button" className="btn btn-default deniet">
                  RECHAZAR
                </button> */}
                <Link to={`introcheck/?code=${UrlCode}`}>
                  <Button2
                    text="SIGUIENTE"
                    // onClick={(e) => {}}
                    width="177px"
                    height="5vh"
                  />
                </Link>
                {/* <button
                  type="button"
                  className="btn btn-default acept"
                  onClick={() => showAlert()}
                  // disabled={acept === false}
                  // onClick={ () => props.conexion()}
                >
                  ACEPTAR
                </button> */}
              </Col>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default TerminosIntroPage;
