import React, { useEffect } from "react";
import TextFinal from "../textFinal";
import idImage from "./../../assets/cedula.png";
import "./TerminosAdvices.scss";
import { Link, useHistory } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import { Button2 } from "../utils/Buttons/Buttons";
import { addEvent } from "../api/Events/EventsApi";
import StorageManager from "../utils/StorageManager";
function TerminosAdvices(props) {
  const query = useQuery();
  const UrlCode = query.get("code");

  // Storage and skipping
  const history = useHistory();
  const storageManager = new StorageManager();
  useEffect(() => {
    let project = storageManager.getProject();
    console.log(project, "project");
    if (project.extraConfig) {
      if (project.extraConfig.showDocumentTips === false) {
        history.push(`/advices2?code=${UrlCode}`);
      }
    }
  }, []);
  return (
    <div className="advices">
      <div
        style={{
          color: "white",
          textAlign: "center",
        }}
      >
        <h1 className="advice-title">
          ¡ Es Importante ! <br></br>antes de comenzar
        </h1>

        <div className="advice1">
          <img src={idImage} className="img1" />
          <div className="txt-final-1">
            <TextFinal type="h1" txt={"Tener a la mano"} />
            <TextFinal type="h1" txt={"tu documento"} />
            <TextFinal type="h1" txt={"de identidad"} />
          </div>
        </div>

        {/* 
        <Link to={`/advices2?code=${UrlCode}`}>
          <button
            className="btn-start"
            onClick={() => {
              
            }}
          >
            <h1>SIGUIENTE</h1>
          </button>
        </Link> */}
        <div>
          <Link to={`/advices2?code=${UrlCode}`}>
            <Button2
              text="SIGUIENTE"
              onClick={(e) => {
                //ask here about this event
                addEvent("ADVICE_1", {}, {}, "interaction");
              }}
              width="177px"
              height="5vh"
            />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default TerminosAdvices;
