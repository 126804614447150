import React, { useState } from "react";
// import { Icon, InlineIcon } from '@iconify/react';
// import envelopeAdd from '@iconify-icons/uil/envelope-add';
import newMessage from "../../assets/newMessage.png";
import { Link, useHistory } from "react-router-dom";
import { ReactComponent as Envelope } from "../../assets/envelop.svg";
import useQuery from "../../hooks/useQuery";
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";
import { Button2 } from "./../utils/Buttons/Buttons";
import "./ModifyEmail.scss";
// import { Row, Container } from "react-bootstrap";
function ModifyEmail(props) {
  let history = useHistory();
  const [editemail, setEditemail] = useState(props.email);
  const [loadingModifyEmail, setLoadingModifyEmail] = useState(false);
  const query = useQuery();
  const UrlCode = query.get("code");

  const Onchange = (e) => {
    setEditemail(e.target.value.toLowerCase());
  };

  const onclick = async () => {
    setLoadingModifyEmail(true);
    props.setEmail(editemail);
    await props.api.modifyEmail(editemail);
    setLoadingModifyEmail(false);
    history.push(`/vemail?code=${UrlCode}`);
  };

  if (loadingModifyEmail) {
    return (
      <div
        className="m-email"
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <ClipLoader color="#fff" size={"120px"} />{" "}
        <h5 style={{ fontSize: "20px", width: "50%", margin: "22px 0 0 0" }}>
          Modificando correo
        </h5>
      </div>
    );
  } else {
    return (
      <div className="m-email">
        <Envelope style={{ margin: "24px 0 0 0" }} />

        <p>Ingresa tu correo</p>

        <div className="content-email">
          <input
            className="input-e"
            value={editemail}
            onChange={Onchange}
            type="email"
          />
        </div>
        {/* <div>
        <button className="btn-siguiente" onClick={() => onclick()}>
          SIGUIENTE
        </button>
      </div> */}

        <div style={{ marginTop: "15em" }}>
          <Button2
            text="SIGUIENTE"
            onClick={onclick}
            width="56vw"
            height="4vh"
          />
        </div>
      </div>
    );
  }
}
export default ModifyEmail;
