import React, { useEffect, useState, useRef } from "react";
import "./App.css";
import "./sass/General.scss";
/* -------------------------------------------------------- */
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";

/* ---------------------------LOS COMPONENTES------------------------------------- */
// _______________________________INTROS____________________________________________

import TerminosIntroPage from "./components/TerminosIntroPage/TerminosIntroPage";
import IntroPage from "./components/IntroPage/IntroPage";
import IntroCheck from "./components/IntroCheck/IntroCheck";
import ReadMore from "./components/IntroCheck/ReadMore";
// _________________________________________________________________________
// import VerifyMailPage from "./components/InstruccionGeo/InstruccionGeo";
import LastVoiceMsg from "./components/RecolectDataVideo/Voice/LastVoiceMsg";
import VerifyMailPage from "./components/VerifyEmailPage/VerifyEmailPage";
import InstruNITionFinal from "./components/InstruccionFinal";
import RecolectDirection from "./components/RecolectDirection/RecolectDirection";
import RecolectDataVideo from "./components/RecolectDataVideo/RecolectDataVideo";
import RecolectDataVideo2 from "./components/Liveness/RecolectDataVideo2";
import RecolectDataVideoFace from "./components/RecolectDataVideo/RecolectDataVideoFace";
import RecolectQuestions from "./components/RecolectQuestions";
import RecolectBiometrics from "./components/RecolectBiometrics";
import RecolectFinal from "./components/RecolectFinal/RecolectFinal";
import RecolectVerify2 from "./components/RecolectVerify2/RecolectVerify2";
import RecolectVerify from "./components/RecolectVerify/RecolectVerify";
import HuellaBiometrica from "./components/HuellaBiometrica/Huellabiometrica";
// _________________________SCREENS:ADVICES_________________________________
import TerminosAdvices from "./components/TerminosAdvices/TerminosAdvices";
import TerminosAdvices2 from "./components/TerminosAdvices/TerminosAdvices2";
import TerminosAdvices3 from "./components/TerminosAdvices/TerminosAdvices3";
// _________________________________________________________________________
import RecolectDataStart from "./components/RecolectDataStart/RecolectDataStart";
import RecolectDataStart2 from "./components/RecolectDataStart/RecolectDataStart2";
import ModifyEmail from "./components/ModifyEmail/ModifyEmail";
import ModifyPhone from "./components/ModifyPhone/ModifyPhone";
import QrFlowIntro from "./components/QrFlow/QrFlowIntro";

/* -------------------------------------------------------------------------- */

// import Webcam from "react-webcam";
import { IconButton, Button } from "@material-ui/core";
import { ReactMic } from "react-mic";
import { DbConnectionSingleton } from "./components/db_connection/db_connection_singleton.js";
import * as tf from "@tensorflow/tfjs";
import Models from "./components/api/api-models";

// import styled from "styled-components";
import Api from "./components/api/api";
import ApiStats from "./components/api/stats/api_stats";

import PhonePhoto from "./components/phonePhoto";
import {
  fatalError,
  commonError,
  infoMessage,
} from "./components/utils/ErrorHandler/errorHandler";
import DataDoneScreen from "./components/DataDoneScreen/DataDoneScreen";
import getGeolocation from "./hooks/getGeolocation";
import { isMobile } from "./components/utils/utils";

import DataRepo from "./components/utils/dataRepo";

import ModelsLoader from "./components/utils/modelsLoader";
import CloseWindowScreen from "./components/CloseWindowScreen";
import ConfirmEmailScreen from "./components/ConfirmEmailScreen/ConfirmEmailScreen";
import FaceAuth from "./components/RecolectDataVideo/FaceAuth";
import QrFlowAuth from "./components/QrFlow/QrFlowAuth";
import ScanPassport from "./components/RecolectDataVideo/ScanPassport";
import StorageManager from "./components/utils/StorageManager";
import AvisoBioPay from "./components/AvisoBioPay/AvisoBioPay";
import VideoFacePay from "./components/RecolectDataVideo/VideoFacePay";
import Voice from "./components/RecolectDataVideo/Voice/Voice";
import LoaderPageInicial from "./components/LoaderPageInitial/LoaderPageInitial";
import ConfirmEmailInPlaceScreen from "./components/ConfirmEmailScreen/ConfirmEmailInPlaceScreen";
import ConfirmData from "./components/ConfirmData/ConfirmData";
import AgentFinal from "./components/agentFinalScreen/agentFinalScreen";
function AppRouter(props) {
  //COLOR_BACKGROUND_COMMAND_EFFECT

  const [bgColor, setBgColor] = useState(["#047bd6", "#000e1a"]);
  const [brandConfigLoaded, setBrandConfigLoaded] = useState(false);
  const [logoUrl, setLogoUrl] = useState("a");
  let history = useHistory();

  const [showLoader, setshowLoader] = useState(false);
  let [isLoading, setLoading] = useState(false);
  const [country, setCountry] = useState("");
  // Storage Manager
  const [storageManager, _] = useState(new StorageManager());

  //new Loader, checking..

  //DeviceId
  const [deviceIdInfo, setDeviceIdInfo] = useState();
  const dbConnection = DbConnectionSingleton.getInstance();
  const [api, setApi] = useState(new Api(setDeviceIdInfo));

  //ModelsLoader
  const [modelsLoader, setModelsLoader] = useState(new ModelsLoader(tf));

  //Loading Overlay

  const [projectId, setProjectId] = useState("");
  const [token, setToken] = useState("");
  const [registrationId, setRegistrationId] = useState("");

  //Needs
  const [needsRut, setNeedsRut] = useState(false);
  const [docTypes, setDocTypes] = useState([]);
  const [needsPassword, setNeedsPassword] = useState(false);
  const [needsPase, setNeedsPase] = useState(false);
  const [needsTarjetaPropiedad, setNeedsTarjetaPropiedad] = useState(false);
  //data repo
  const [dataRepo, setDataRepo] = useState(new DataRepo());

  const [dataRoute, setDataRoute] = useState();

  //Data user
  const [userId, setUserId] = useState("");

  useEffect(() => {
    console.log("NeedsRutChangeed!!");
    console.log(needsRut);
    dataRepo.needsRut = needsRut;
    console.log(dataRepo);
  }, [needsRut]);

  //Supported doc types
  useEffect(() => {
    console.log("supported doctypes changed!!");
    console.log(docTypes);
    dataRepo.supportedDocTypes = docTypes;
    console.log(dataRepo);
  }, [docTypes]);

  useEffect(() => {
    api.isQr();
  }, []);

  async function getBrandColors() {
    let code = api.getQueryStringVal("code");
    if (code) {
      try {
        let res = await api.getBrandConfig(code);
        if (res) {
          if (res.firstColorHex && res.secondColorHex) {
            setBgColor([`#${res.firstColorHex}`, `#${res.secondColorHex}`]);
          }
          console.log("LogoUrl");
          console.log(res.logoUrl);
          if (res.logoUrl) {
            setLogoUrl(res.logoUrl);
          } else {
            setLogoUrl("error");
          }
        }
      } catch (error) {
        setLogoUrl("error");
      }
    }
    setBrandConfigLoaded(true);
  }

  async function getBrandColorsAuth() {
    let code = api.getQueryStringVal("authCode");
    if (code) {
      let res = await api.getBrandConfigAuth(code);
      if (res) {
        if (res.firstColorHex && res.secondColorHex)
          setBgColor([`#${res.firstColorHex}`, `#${res.secondColorHex}`]);
      }
    }
  }

  useEffect(() => {
    getBrandColors();
    getBrandColorsAuth();
  }, []);

  //Data
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [needsOtp, setNeedsOtp] = useState(false);
  const currentIdRef = useRef(1);
  const [canEdit, setCanEdit] = useState(true);

  const setPhoneEmail = (phone2, email2) => {
    setEmail(email2);
    setPhone(phone2);
  };

  const gotoCloseWindowScreen = () => {
    history.push("/closeWindow");
  };

  // api.getPreloadedData().then((alldata) => {
  //   setDataRoute(alldata.code);
  // });

  const loadAll = async () => {
    const afterProjectId = (code, proj) => {
      setProjectId(proj.id);
      setNeedsPassword(proj.supportsAuth);
      console.log(projectId);
      api.gotoCloseWindowScreen = gotoCloseWindowScreen;
      api
        .initialRegistration(code, proj, setToken, setRegistrationId)
        .then(() => {
          console.log("THEEEEEEEEEEEEEEEN");
          console.log("THEEEEEEEEEEEEEEENs");
          console.log(api.isQr());
          if (api.isQr()) {
            api.assignReg();
          }
          if (proj.hasLocalization === true) getGeolocation({ api });
        });
    };

    let queryCode = api.getQueryStringVal("code");
    console.log(queryCode, "QUERYCODE");
    if (queryCode === null) {
      commonError("Su código es incorrecto, por favor verifique su acceso.");
      console.log("SU CODIGO ES INCORRECTO");
    } else {
      await dbConnection.getProjectAsync(
        queryCode,
        afterProjectId,
        setNeedsOtp,
        setNeedsRut,
        setNeedsPassword,
        setNeedsPase,
        setNeedsTarjetaPropiedad,
        setCountry,
        setDocTypes
      );
    }

    await modelsLoader.loadModels(tf);
  };
  if (!brandConfigLoaded) return <div></div>;
  return (
    <div
      className="App2"
      id={currentIdRef.current === 4 ? "video-stream" : "App2"}
      style={{
        minWidth: "100vw",
        background: `radial-gradient(${bgColor[0]}, ${bgColor[1]})`,
      }}
    >
      <div className="filter"></div>
      <div
        className="content"
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Router>
          <Switch>
            <Route exact path="/">
              <TerminosIntroPage logoUrl={logoUrl} />
            </Route>
            <Route exact path="/introcheck">
              <IntroCheck
                showLoader={showLoader}
                isLoading={isLoading}
                setshowLoader={setshowLoader}
                api={api}
              />
            </Route>
            <Route path="/intro">
              <LoaderPageInicial
                conexion={loadAll}
                showLoader={showLoader}
                isLoading={isLoading}
                setshowLoader={setshowLoader}
                api={api}
              />
            </Route>

            <Route exact path="/readmore">
              <ReadMore
                showLoader={showLoader}
                isLoading={isLoading}
                setshowLoader={setshowLoader}
                api={api}
              />
            </Route>
            <Route exact path="/qrFlow">
              <QrFlowIntro api={api} logoUrl={logoUrl} />
            </Route>

            <Route exact path="/qrFlowAuth">
              <QrFlowAuth api={api} />
            </Route>

            <Route exact path="/recolect">
              {/*        <RecolectDataStart2
             
                api={api}
                setPhoneEmail={setPhoneEmail}
                dataRepo={dataRepo}
                dataRoute={dataRoute}
                setCanEdit={setCanEdit}
              /> */}
              <RecolectDataStart
                api={api}
                setPhoneEmail={setPhoneEmail}
                dataRepo={dataRepo}
                dataRoute={dataRoute}
                setCanEdit={setCanEdit}
              />
            </Route>

            <Route exact path="/advices">
              <TerminosAdvices token={token} />
            </Route>

            <Route exact path="/advices2">
              <TerminosAdvices2 token={token} />
            </Route>

            <Route exact path="/advices3">
              <TerminosAdvices3 token={token} />
            </Route>

            <Route exact path="/biometrics">
              <RecolectDataVideo
                api={api}
                token={token}
                registrationId={registrationId}
                modelsLoader={modelsLoader}
                tf={tf}
                needsPase={needsPase}
                needsTarjetaPropiedad={needsTarjetaPropiedad}
                country={country}
                bg1={bgColor[0]}
                bg2={bgColor[1]}
              />
            </Route>
            {/* Passport Route */}
            <Route exact path="/scanPassport">
              <ScanPassport
                api={api}
                token={token}
                registrationId={registrationId}
                modelsLoader={modelsLoader}
                tf={tf}
                needsPase={needsPase}
                needsTarjetaPropiedad={needsTarjetaPropiedad}
                country={country}
                bg1={bgColor[0]}
                bg2={bgColor[1]}
              />
            </Route>

            <Route exact path="/videoface">
              <RecolectDataVideoFace
                api={api}
                token={token}
                registrationId={registrationId}
                modelsLoader={modelsLoader}
                tf={tf}
                needsPase={needsPase}
                needsTarjetaPropiedad={needsTarjetaPropiedad}
                country={country}
              />
            </Route>
            <Route exact path="/voice">
              <Voice
                api={api}
                token={token}
                registrationId={registrationId}
                modelsLoader={modelsLoader}
                tf={tf}
                needsPase={needsPase}
                needsTarjetaPropiedad={needsTarjetaPropiedad}
                country={country}
              />
            </Route>
            <Route exact path="/voicemsg">
              <LastVoiceMsg />
            </Route>
            <Route exact path="/huella">
              <HuellaBiometrica />
            </Route>

            <Route exact path="/avisosPay">
              <AvisoBioPay />
            </Route>

            <Route exact path="/videofacePay">
              <VideoFacePay
                api={api}
                token={token}
                registrationId={registrationId}
                modelsLoader={modelsLoader}
                tf={tf}
                needsPase={needsPase}
                needsTarjetaPropiedad={needsTarjetaPropiedad}
                country={country}
              />
            </Route>

            <Route path="/auth">
              {" "}
              <FaceAuth tf={tf} api={api} modelsLoader={modelsLoader} />
            </Route>

            <Route exact path="/liveness">
              <RecolectDataVideo2
                api={api}
                token={token}
                registrationId={registrationId}
                modelsLoader={modelsLoader}
                tf={tf}
                needsPase={needsPase}
                needsTarjetaPropiedad={needsTarjetaPropiedad}
                country={country}
              />
            </Route>

            <Route exact path="/vemail">
              <VerifyMailPage
                email={email}
                api={api}
                needsOtp={needsOtp}
                canEdit={canEdit}
              />
            </Route>

            <Route exact path="/confirmEmail">
              <ConfirmEmailScreen api={api} />
            </Route>
            <Route exact path="/confirmEmailInplace">
              <ConfirmEmailInPlaceScreen api={api} />
            </Route>

            <Route exact path="/vsms">
              <RecolectVerify2 api={api} phone={phone} canEdit={canEdit} />
            </Route>

            <Route path="/direction">
              <RecolectDirection
                userId={userId}
                api={api}
                needsPassword={needsPassword}
              />
            </Route>

            <Route path="/password">
              {" "}
              <RecolectFinal api={api} />
            </Route>

            <Route path="/final">
              <DataDoneScreen api={api} />
            </Route>

            <Route path="/closeWindow">
              <CloseWindowScreen />
            </Route>
            {/* --------------------RUTAS ALTERNAS----------------------------*/}

            <Route exact path="/email">
              <ModifyEmail setEmail={setEmail} email={email} api={api} />
            </Route>

            <Route exact path="/phone">
              <ModifyPhone setPhone={setPhone} phone={phone} api={api} />
            </Route>
            <Route exact path="/confirmData">
              <ConfirmData api={api} />
            </Route>
            <Route exact path="/agentFinal">
              <AgentFinal api={api} bg1={bgColor[0]} bg2={bgColor[1]} />
            </Route>
          </Switch>
        </Router>
      </div>
    </div>
  );
}

export default AppRouter;
