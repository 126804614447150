import React, { useState, useEffect } from "react";
import icono1 from "../../static/ICO USER.png";
import icono2 from "./../../assets/scan.png";
import icono3 from "./../../assets/video.png";
import icono4 from "../../static/ICO UBICACION.png";
import icono5 from "./../../assets/microphone.png";
import icono6 from "../../static/ICO SMS.png";
// import iconoanteia from "../static/00 PAV 01.png";
import iconoanteia from "../../assets/logo_anteia.png";
import Checkbox from "@material-ui/core/Checkbox";
import { Container, Row, Col } from "react-bootstrap";
import IntroLogo from "../IntroLogo/IntroLogo";
import IntroPage from "./../IntroPage/IntroPage";
// import { infoMessage } from "./utils/errorHandler";
import { Link, useHistory } from "react-router-dom";
import "./LoaderPageInitial.scss";
import useQuery from "../../hooks/useQuery";
import { isMobile } from "../utils/utils";
import { infoMessage } from "../utils/ErrorHandler/errorHandler";
import alert from "./../../assets/alert.png";
import { Button2 } from "./../utils/Buttons/Buttons";
import LoadingOverlay from "react-loading-overlay";
function LoaderPageInicial(props) {
  const [index, setIndex] = useState(1);
  console.log("props", props);
  const query = useQuery();
  const UrlCode = query.get("code");
  const history = useHistory();

  

  useEffect(() => {
    loadAll();
  }, []);

  async function loadAll() {
    props.setshowLoader(true);
    props.api.spoolUp();
    console.log("PROPPPPPS.CONEXION en LOADERPAGE INITIAL")
    console.log("PROPPPPPS.CONEXION en LOADERPAGE INITIAL1")
    console.log("PROPPPPPS.CONEXION en LOADERPAGE INITIAL2")
    console.log("PROPPPPPS.CONEXION en LOADERPAGE INITIAL3")
    console.log("PROPPPPPS.CONEXION en LOADERPAGE INITIAL4")
    console.log("PROPPPPPS.CONEXION en LOADERPAGE INITIAL5")
    await props.conexion();
    console.log("AFTERRRRRRR PROPPPPPS.CONEXION en LOADERPAGE INITIAL")
    console.log("AFTERRRRRRR PROPPPPPS.CONEXION en LOADERPAGE INITIAL1")
    console.log("AFTERRRRRRR PROPPPPPS.CONEXION en LOADERPAGE INITIAL2")
    console.log("AFTERRRRRRR PROPPPPPS.CONEXION en LOADERPAGE INITIAL3")
    console.log("AFTERRRRRRR PROPPPPPS.CONEXION en LOADERPAGE INITIAL4")
    console.log("AFTERRRRRRR PROPPPPPS.CONEXION en LOADERPAGE INITIAL5")
    history.push(`/recolect?code=${UrlCode}`);

    console.log("pushed route...");
  }

  return (
    <>
      {/* <Loader txts={["Vamos a comenzar"]} /> */}
      <LoadingOverlay
        active={true}
        spinner
        text={"Vamos a comenzar"}
        classNamePrefix="MyLoader_spinner"
      />
    </>
  );
}

export default LoaderPageInicial;
