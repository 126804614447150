import React, { useEffect } from "react";
import TextFinal from "../textFinal";
import "./TerminosAdvices.scss";
import { Link, useHistory } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import { Button2 } from "../utils/Buttons/Buttons";
import handsimg from "./../../assets/imghands.png";
import triangleimg from "./../../assets/triangle.png";
import { addEvent } from "../api/Events/EventsApi";
import StorageManager from "../utils/StorageManager";
function TerminosAdvices3() {
  const query = useQuery();
  const UrlCode = query.get("code");

  // Storage and skipping
  const history = useHistory();
  const storageManager = new StorageManager();
  useEffect(() => {
    let project = storageManager.getProject();
    console.log(project);
    if (project.extraConfig) {
      if (project.extraConfig.showDocumentTips === false) {
        history.push(`/biometrics?code=${UrlCode}`);
      }
    }
  }, []);
  return (
    <div
      className="advices3"
      style={{
        color: "white",
        textAlign: "center",
      }}
    >
      <div className="advices3-txt">
        <TextFinal type="h1" txt={"Coloca tu documento"} />
        <TextFinal type="h1" txt={"sobre una superficie"} />
        <TextFinal type="h1" txt={"plana antes de comenzar"} />
        <img src={handsimg} alt="sun" style={{ width: "266px" }} />
      </div>

      <div className="advices3-txt">
        <div className="only-txt-big">
          <TextFinal type="h1" txt={"Un Consejo"} />
        </div>

        <TextFinal type="h1" txt={"Inclina 20° grados tu"} />
        <TextFinal type="h1" txt={"documento"} />
        <img src={triangleimg} alt="sun" style={{ width: "180px" }} />
      </div>
      <div className="advices3-txt">
        <TextFinal type="h2" txt={"Así lograrás menos reflejos"} />
        <TextFinal type="h2" txt={"y será mas fácil la captura"} />
        <TextFinal type="h2" txt={"de este"} />
      </div>
      <div style={{ marginTop: "4em" }}>
        <Link to={`/biometrics?code=${UrlCode}`}>
          <Button2
            text="SIGUIENTE"
            width="177px"
            height="5vh"
            onClick={(e) => {
              addEvent("ADVICE_3", {}, {}, "interaction");
            }}
          />
        </Link>
      </div>
    </div>
  );
}
export default TerminosAdvices3;
