import React from "react";
import es from "date-fns/locale/es";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DateTextField.scss";
registerLocale("es", es);

export const DateTextField = ({
  label,
  selectedDate,
  setSelectedDate,
  maxDate,
  minDate,
  disabled,
  errorText,
}) => {
  const labelStyle = {
    position: "absolute",
    top: "-0.55rem",
    left: "1.5rem",
    pointerEvents: "none",
    transition: "all 0.2s ease-out",
    textTransform: "uppercase",
    fontSize: "0.75rem",
    color: "rgba(255,255,255,0.75)",
  };
  return (
    <div style={{ position: "relative", marginBottom: "1rem" }}>
      <DatePicker
        locale="es"
        selected={selectedDate}
        onChange={(date) => {
          setSelectedDate(date);
        }}
        dateFormat="dd/MM/yyyy"
        maxDate={maxDate}
        minDate={minDate}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        className="custom-datepicker"
        disabled={disabled}
        onFocus={(e) => e.target.blur()}
      />
      <label style={labelStyle}>{label}</label>
      {errorText && (
        <p
          style={{
            color: "#FF7575",
            fontSize: "10px",
            margin: "3px 0 0 3px",
            width: "240px",
          }}
        >
          {errorText}
        </p>
      )}
    </div>
  );
};
