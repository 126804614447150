import React, { useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import { Link, useHistory } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import StorageManager from "../utils/StorageManager";
import "./DataDoneScreen.scss";
import Loader from "../utils/Loader/Loader";
const secondsToWait = 120;

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function getTime() {
  var d = new Date();
  return d.getTime();
}

function DataDoneScreen(props) {
  const storageManager = new StorageManager();
  let history = useHistory();
  const query = useQuery();
  function gotoClose() {
    history.push("/closeWindow");
  }

  const UrlCode = query.get("code");
  function gotoAgent() {
    history.push(`/agentFinal?code=${UrlCode}`);
  }
  useEffect(() => {
    let project = storageManager.getProject();
    async function getDataDone() {
      let flag = await props.api.getDataDone();
      let initialTime = null;
      while (!flag) {
        if (!initialTime) initialTime = getTime();
        await sleep(2000);
        flag = await props.api.getDataDone();
        console.log("dataDone:");
        console.log(flag);
        let dif = getTime() - initialTime;
        console.log("time dif: " + dif);
        if (dif > secondsToWait * 1000) {
          flag = true;
        }
      }
      if (project && project.extraConfig && project.extraConfig.canConfirm) {
        history.push(`/confirmData?code=${UrlCode}`);
      } else {
        props.api.final(gotoClose, gotoAgent);
      }
    }
    getDataDone();
  }, []);
  return (
    <div
      style={{
        color: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      <h2 style={{ margin: "30px 0 0 0", textAlign: "center" }}>
        Estamos terminando tu verificación...
      </h2>
      <Loader />
      {/* <LoadingOverlay
          active={true}
          // spinner={<BounceLoader />}
          spinner={true}
        />  */}

      <h2
        style={{ fontSize: "28px", margin: "0 0 45px 0", textAlign: "center" }}
      >
        Por favor espera mientras procesamos tu información
      </h2>
    </div>
  );
}
export default DataDoneScreen;
